@font-face {
    font-family: "SiemensSans_Prof";
    src: url("/fonts/SiemensSans_Prof_Roman.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
}

/* @font-face {
    font-family: 'SiemensSans_Prof';
    src: url('/fonts/SiemensSans_Prof_Italic.woff2') format('woff2');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'SiemensSans_Prof';
    src: url('/fonts/SiemensSans_Prof_Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
} */

@font-face {
    font-family: "SiemensSans_Prof";
    src: url("/fonts/SiemensSans_Prof_Black.woff2") format("woff2");
    font-weight: 900;
    font-style: normal;
}
