@tailwind base;
@tailwind components;
@tailwind utilities;

/* ------------- FrutigerLTCom Fonts ------------- */

@font-face {
    font-family: "FrutigerLTCom-BlackItalic";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "FrutigerLTCom-Black";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-Black.ttf") format("truetype");
}

@font-face {
    font-family: "FrutigerLTCom-Bold";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "FrutigerLTCom-Roman";
    src: url("../../public/fonts/frutigerLTCom/FrutigerLTCom-Roman.ttf") format("truetype");
}

@font-face {
    font-family: "Raleway.ttf";
    src: url("../../public/fonts/Raleway.ttf") format("truetype");
}

@font-face {
    font-family: "Helvetica Neue.ttf";
    src: url("../../public/fonts/Helvetica Neue.ttf") format("truetype");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 100 900;
    font-display: optional;
    src: url("/fonts/inter-var-latin.woff2") format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@layer base {
    /* ------------- Spin Animation for Loading Spinners ------------- */
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

@layer components {
    /* ------------- Hide Scrollbar ------------- */
    .scrollbar-hidden {
        overflow: hidden;
        scrollbar-width: none;
    }

    .scrollbar-hidden::-webkit-scrollbar {
        display: none;
    }

    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .blackShade-border {
        @apply border-blackShade-15 border;
    }

    .card-button-border {
        @apply border-blackShade-15 border;
    }

    .headline-container {
        @apply flex w-[361px] flex-col items-center gap-6;
    }
}

@layer utilities {
}

.shadow-card {
    display: flex;
    width: 361px;
    padding: 32px 32px 40px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: var(--White, #fff);
    box-shadow:
        0px 55px 15px 0px rgba(0, 0, 0, 0),
        0px 35px 14px 0px rgba(0, 0, 0, 0.01),
        0px 20px 12px 0px rgba(0, 0, 0, 0.04),
        0px 9px 9px 0px rgba(0, 0, 0, 0.07),
        0px 2px 5px 0px rgba(0, 0, 0, 0.08);
}

.storyScrollbars::-webkit-scrollbar {
    display: none;
}

/* .storyScrollbars::-webkit-scrollbar {
    cursor: pointer;
    width: 18px;
}


.storyScrollbars::-webkit-scrollbar-track {
    background-color: #23233c;
    border-radius: 999px;
}


.storyScrollbars::-webkit-scrollbar-thumb {
    background-color: #000028;
    border: 4px solid #23233c;
    border-radius: 999px;
}


.storyScrollbars::-webkit-scrollbar-thumb:hover {
    background-color: #00ffb9;
}

.strategyScrollbars::-webkit-scrollbar {
    cursor: pointer;
    width: 4px;
}

.strategyScrollbars::-webkit-scrollbar-track {
    background: #000028;
}

.strategyScrollbars::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: #00cccc;
}

.strategyScrollbars::-webkit-scrollbar-thumb:hover {
    background-color: #00ffb9;
} */

.animatedHeight {
    transition: height 1s ease 0.3s;
}

.animatedMargin {
    transition: all 1s ease 0.3s;
}

@keyframes chatEnter {
    0% {
        transform: translateX(110%);
        opacity: 0;
    }

    100% {
        transform: translateX(10px);
        opacity: 100;
    }
}

.chatEnter {
    animation: chatEnter 1s ease 0.3s both;
}

.chatBubble {
    border-radius: 16px 64px 64px 64px;
    border: 4px solid rgba(255, 255, 255, 0.65);
    background: url("/images/bubbleBackground.png");
    box-shadow: 0px 0px 50px 0px rgba(31, 85, 97, 0.3);
    backdrop-filter: blur(36px);
}

.chatBubbleRight {
    border-radius: 64px 16px 64px 64px;
    border: 4px solid rgba(255, 255, 255, 0.65);
    background: url("/images/bubbleBackground.png");
    box-shadow: 0px 0px 50px 0px rgba(31, 85, 97, 0.3);
    backdrop-filter: blur(36px);
}

.selectionBubble {
    border-radius: 64px;
    background: url("/images/bubbleBackground.png");

    background-blend-mode: multiply, normal;
    box-shadow:
        0px 0px 72px 30px rgba(255, 255, 255, 0.6) inset,
        0px 0px 60px 0px rgba(255, 255, 255, 0.3);
    /* backdrop-filter: blur(36px); */
}

.chatButtonFadeIn {
    opacity: 0.00001;
    /* Set initial opacity to 0 */
    animation: chatButtonFadeInAnimation 1s ease forwards;
    /* Define the animation */
}

@keyframes chatButtonFadeInAnimation {
    from {
        opacity: 0.00001;
    }

    to {
        opacity: 1;
    }
}

.chatButtonFadeOut {
    opacity: 1;
    /* Set initial opacity to 0 */
    animation: chatButtonFadeOutAnimation 1s ease forwards;
    /* Define the animation */
}

@keyframes chatButtonFadeOutAnimation {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
